import React, { CSSProperties, useCallback, useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import './App.css';
import { AppLocalizationProvider } from './shared/i18n/AppLocalizationProvider.component';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

import cossinImage from './assets/emotions/Cossin_Cossin.jpg';
import careImage from './assets/emotions/care.jpg';
import sheepImage from './assets/emotions/sheep.jpg';
import { LanguageSelector } from './shared/i18n/LanguageSelector.component';

type Emotion = {
    image: string;
    position: string;
    text: string;
    textStyle: CSSProperties;
}

type EmotionFade = Emotion & {completed: number};

const emotions = [
    {image: cossinImage, position: '78% 60%', text: 'cossin', textStyle: {
        top: '30%',
        left: '30%',
        width: '40%', align: 'left'}} as Emotion,
    {image: careImage, position: '45% 50%', text: 'care', textStyle: {top: '10%',
    left: '10%',
    width: '70%', align: 'right'}} as Emotion,
    {image: sheepImage, position: '25% 40%', text: 'sheep', textStyle: {top: '20%',
    left: '20%', 
    width: '60%',
 align: 'center'}} as Emotion,
];

const fadeTime = 1000;


function App() {
  const { t } = useTranslation();
    
  const [emotion, setEmotion] = useState<Emotion>(emotions[0]);
  const [fades, setFades] = useState<EmotionFade[]>([]);

  const cleanupFadedEmotions = useCallback((changed : EmotionFade[]) => {
    const now = new Date().getTime();
    const completed = changed.filter((fade) => fade.completed <= now);
    if (completed.length > 0) {
        setEmotion(completed[completed.length - 1]);
    }
  }, [setEmotion]);

  const switchTo = useCallback((next: Emotion) => {
      const now = new Date().getTime();
      const change = [...fades.filter((fade) => fade.completed >= now)];
      change.push({...next, completed: now + fadeTime});
      setFades(change);
      setTimeout(() => {
            cleanupFadedEmotions(change);
        }, fadeTime);
      }, [fades, setFades, cleanupFadedEmotions]
  );

  return (
      <>
    <div className="App" style={{backgroundImage: `url(${emotion.image})`, backgroundPosition: emotion.position }}>
        { fades.map((fade) => <div className='App fadein' key={fade.completed} style={{backgroundImage: `url(${fade.image})`, backgroundPosition: fade.position }}></div>)}
    </div>

    <AppLocalizationProvider>
      <div className="Emote" style={emotion.textStyle}>
      {t(emotion.text)}
      {emotion.text === 'care' ? <a href="./Aufnahmeantrag Cossin Cares 2022-07-12.pdf" style={{display:'block', textAlign: 'right'}}><DownloadIcon /></a> : ''}
      {emotion.text === 'sheep' ? <div style={{fontSize: '2vw' }}>Cossin Cares e.V.<br/>Vereinsvorstand: Cindy Hertel-Mihatsch, Jurij Handera, Dirk Jarosch<br/>Dorfstraße 23<br/>14913 Kossin</div> : ''}
      </div>

      <header className="App-header">
        <Button onClick={() => {
            switchTo(emotions[0]);  
            }}>
          {t('about')}
        </Button>
        <Button onClick={() => {
            switchTo(emotions[1]);
            }}>
          {t('donate')}
        </Button>
        <Button onClick={() => {
            switchTo(emotions[2]);
            }}>
          {t('imprint')}
        </Button>

         <LanguageSelector />
      </header>
        </AppLocalizationProvider>
    </>
  );
}

export default App;
