import { ReportHandler } from "web-vitals";

export function sendToAnalytics(metric: any) {
  const body = '"' + metric.name + '":' + metric.value;
  let ui = localStorage.getItem("ui");
  if (!ui) {
    ui = (+("" + Math.random()).replace(/[,.]/g, "")).toString(36);
    localStorage.setItem("ui", ui);
  }
  const url = "/analytics.php?ui=" + ui;

  if (navigator.sendBeacon) {
    navigator.sendBeacon(url, body);
  } else {
    fetch(url, { body, method: "POST", keepalive: true });
  }
}

export function reportWebVitals(onPerfEntry?: ReportHandler) {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import("web-vitals").then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
}
