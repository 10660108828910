import React from 'react';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { PropsWithChildren } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
type AppLocalizationProviderProps = PropsWithChildren<{}>;

export function AppLocalizationProvider({
    children,
}: AppLocalizationProviderProps) {
    return (
        <LocalizationProvider
            dateAdapter={AdapterDateFns}
        >
            {children}
        </LocalizationProvider>
    );
}
