import React from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import Flag from 'react-world-flags';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export function LanguageSelector() {
    const { t } = useTranslation();

    const handleChange = (event: SelectChangeEvent<string>) => {
        const {
          target: { value },
        } = event;
        i18n.changeLanguage(value);
      };
    

    return <FormControl sx={{ m: 1, width: 300 }}>
    <InputLabel id="select-label">{t('language')}</InputLabel>
    <Select labelId="select-label"
      value={i18n.language}
      onChange={handleChange}
      input={<OutlinedInput label={t('language')} />}
      renderValue={(selected) => (<span><Flag className="flag" code={selected === 'en' ?'gb': selected} />{t('language.'+selected)}</span>)}
      MenuProps={MenuProps}
    >
    {(i18n.options.supportedLngs || []).filter((l) => l !== 'cimode').map((lang) => (
        <MenuItem key={lang} value={lang}>
        <Flag className="flag" code={lang === 'en' ?'gb': lang} />
        <ListItemText primary={t('language.'+lang)} />
      </MenuItem>
    ))}
    </Select>
    </FormControl>;
}