import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector";

import de from '../../assets/i18n/de.json';
import en from '../../assets/i18n/en.json';
import pl from '../../assets/i18n/pl.json';
import ru from '../../assets/i18n/ru.json';
import ua from '../../assets/i18n/ua.json';


export const setupI18n = () => {
    i18n.use(initReactI18next)
        .use(LanguageDetector)
        .init({
            // debug: true,
            resources: { de, en, pl, ru, ua },
            detection: { order: ['querystring', 'navigator'], lookupQuerystring: 'lng' },
            fallbackLng: 'en',
            supportedLngs: ['de', 'en', 'pl', 'ua', 'ru'],
            nonExplicitSupportedLngs: true,
            defaultNS: 'i18n',
            ns: ['i18n'],
            interpolation: {
                escapeValue: false, // react already protects against xss
            },
        });
    
    i18n.changeLanguage();

    i18n.on('languageChanged', (lng) => {
        const parentLng = lng?.split(/(_|-)/)[0]; // example: en-GB -> en
        window.document.documentElement.lang = parentLng;
    });
};
